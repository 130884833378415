.information-icon {
    padding: .5%;
    background-color: #D9D9D9;
    float: right;
    height: 120px;
    width: 120px;
    border-bottom-left-radius: 80px;
}

.information-icon > #icon {
    height: 100px;
    float: inherit;
}

.contact-us-page {
    background-color: #FFFCF7;
}

.contact-us-page > .text {
    margin-bottom: 4%;
    padding: 5% 5% 0%;
}

.partners {
    background-color: #E9E3DF;
    border-radius: 35px 35px 0px 0px;
    display: flex;
    justify-content: space-around;
    margin-top: 3%;
    padding: 1% 0.5% 1% 0.2%;
    color: #3F0000
}

@media only screen and (max-width: 700px) {
    .partners {
      flex-direction: column;
    }
}

.partner > .name, .title {
    font-weight: bold;
}

.partner > .title {
    font-style: italic;
}

.partner > .name, .title, .contact-info {
    font-size: 18px;
    font-family: 'DM Sans', sans-serif;
}