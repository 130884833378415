.footer {
    display: flex;
    background-color: #4D3230;
    align-items: center;
    height:5%;
    justify-content: space-between;
}

.footer > p {
    color: #FFFFFF;
    font-size: 13px;
}