.element {
    display: flex;
    flex-direction: column;
    background-color: #FFF2DD;
    width: 25vw;
    min-width: 300px;
    align-items: center;
    padding: 2%;
    border-radius: 57px;
    text-align: center;
    margin-bottom:2%; 
    padding: 3% 2%;
    /* filter: drop-shadow(20px 4px 4px #BFBDB9); */
}

.dark-bg {
    /* filter: drop-shadow(20px 4px 4px #5B3B38) !important; */
}

.element > .text {
    top: -15%;
    font-family: 'DM Sans', sans-serif;
}

.element > .icon {
    height: 150px;
    object-fit: contain;
    padding: 5%;
}

.element > .small-icon {
    position: relative;
    height: 80px;
    top: -5vw;
    left: 50%;
}

.element > h3 {
    text-transform: uppercase;
    color: #3F0000;
    font-size: 30px;
    margin: 0px;
}

.element > p {
    color: #343D48;
    font-size: 25px;
}