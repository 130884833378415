.trainings-page {
    background-color: #FFFCF7;
    padding:  2% 0%;
}

.text {
    padding-bottom: 0%;
}

.text > h1{
    font-family: 'DM Serif Display', serif;
    font-weight: lighter;
    font-size: 50px;
    margin:0px;
    text-align: left;
    color: #3F0000;
}

.trainings-offered {
    width: 88%;
    margin: 5% 0%;
}