h2 {
    font-family: 'DM Sans', sans-serif;
    color: #3F0000;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0.25%
}

p {
    font-family: 'DM Sans', sans-serif;
    font-weight: lighter;
    font-size: 25px;
    text-align: center;
}

.vertical{
    background-color: #FFF2DD;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5%;
    padding: .5%;
    color: #343D48;
    text-align: center;
    padding: 0.5% 8%;
    /* filter: drop-shadow(20px 4px 4px #BFBDB9); */
}

.horizontal{
    background-color: #FFF2DD;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    display: flex;
    align-items: center;
    margin-top: 1.5%;
    padding: .5%;
    text-align: center;
    color: #343D48;
    /* filter: drop-shadow(20px 4px 4px #BFBDB9);*/
}

@media only screen and (max-width: 650px) {
    .horizontal {
      flex-direction: column;
    }
}