.navbar {
    display: flex;
    justify-content: space-between;
    padding-left: 0%;
    float: right;
    align-items: center;
    text-align: center;
}

.navbar a {
    color: #3F0000;
    text-decoration: none;
    padding: 0 1em;
    transition: 0.3s ease-in-out;
    font-size:calc(10px + 0.5vw);
    font-weight: bolder;
    font-family: 'DM Sans', sans-serif;
}

.navbar a:hover {
    color: #4E545C;
    text-decoration: none;
    padding: 0 20px;
    font-weight: bold;
}

.isFooter a{
    color: #FFFFFF !important;
    font-family: 'DM Sans', sans-serif !important;
    font-size: 13px !important;
    font-weight: lighter;
}

@media only screen and (max-width: 500px) {
    .navbar {
        flex-direction: column;   
    }

    .navbar a {
        padding-bottom: 3vh;
    }

    .isFooter a{
        color: #4D3230;
        font-size: 0.01px !important;
    }

    .hideText a{
        font-size: 0px !important;
        color: #E8DBCE; 
        width: fit-content;
        position: absolute;
    }
}