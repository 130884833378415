.about-us-page {
    background-color: #FFFCF7;
    height: 100%;
    padding: 0%;
    display: flex;
    flex-direction: column;
}

.text {
    margin: 0px;
    padding: 3%;
    color: #3F0000;
}

.text > h1 {
    font-family: 'DM Serif Display', serif;
    font-weight: lighter;
    font-size: 60px;
    margin:0px;
    text-align: left;
}

.text > p {
    font-family: 'DM Sans', sans-serif;
    font-weight: lighter;
    font-size: 25px;
    margin:0px;
    margin-bottom: 1%;
    text-align: left;
}

.information-icon {
    padding: .5%;
    background-color: #D9D9D9;
    float: right;
    height: 120px;
    width: 120px;
    border-bottom-left-radius: 80px;
}

.information-icon > #icon {
    height: 70px;
    float: inherit;
}

.about-us-elements {
    margin-right: 5%;
    float: left;
    display: flex;
    flex-direction: column;
}

.about-us-elements > div {
    background-color: #FFF2DD;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    display: flex;
    align-items: center;
    margin-top: 1.5%;
    padding: 2%;
}

.partners-in-success {
    display: flex;
    flex-direction: column;
    background-color: #7A4F4B;
    color: #FFF2DD;
    align-items: center;
    margin: 7% 0%;
    padding: 1% 0% 1% 0%;
    font-family: 'DM Sans', sans-serif;
    font-weight: lighter;
}

.partners-in-success > h1 {
    margin: 0;
    text-align: center;
    text-transform: uppercase;
}

.partners-in-success > p {
    color: #B6BABF;
    padding: 0% 10% 0% 10%;
}