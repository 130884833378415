.home-page {
    background-color: #FFFCF7;
    color: #3F0000;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.welcome-page {
    margin-bottom: 3%;
}

#illustration {
    min-width: 250px;
    width: 30vw;
    max-width: 390px;
    object-fit: contain;
}

.welcome {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 2% 7.5%;
}

#welcome-text {
    width: fit-content;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2%;
}

#welcome-text > h1{
    font-family: 'DM Serif Display', serif;
    font-weight: bolder;
    font-size: 70px;
    margin:0px;
}

#welcome-text > h2,
#welcome-text > p {
    font-family: 'DM Sans';
    font-size: 20px;
    text-align: left;
}

#welcome-text > h2{
    font-weight: bold;
}

.our-mission {
    background-color: #FFF2DD;
    border-radius: 60px;
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
    padding: 2%;
    align-items: center;
    text-align: center;
    /* filter: drop-shadow(20px 4px 4px #BFBDB9); */
}

@media only screen and (max-width: 650px) {
    .our-mission {
      flex-direction: column;
    }
}

.our-mission > h2 {
    width: 40%;
}

.our-mission > p {
    margin: 2.5%;
    font-size: 130%;
    color: #343D48;
}

.choose-us-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1% 0% 1% 0%;
}