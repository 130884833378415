.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #B69D98;
    padding: 2%;
    border-radius: 30px;
    width: 80%;
    max-width: 850px;
    margin: auto;
    /* filter: drop-shadow(0px 4px 4px #BFBDB9); */
}

.contact-form > h3 {
    color: #FFF2DD;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 25px;
}

.contact-form > p {
    width: 98%;
    text-align: left !important;
    color: #3F0000;
}

.contact-form > p::after {
    content: attr(asterisk);
    color: red;
}

.contact-form > input {
    width: 95%;
    height: 2em;
    border: none;
    border-radius: 28px;
    padding: 0% 2%;
    font-family: monospace;
    font-size: 18px;
}

.contact-form > textarea {
    width:95%;
    height:10em;
    border: none;
    border-radius: 28px;
    padding: 2%;
    font-family: monospace;
    font-size: 18px;
}

.contact-form > .button {
    margin: 2%;
    background-color: #D9D9D9;
    padding: 3% 6%;
    border: none;
    border-radius: 150px;
    color: #343D48;
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
}
