.choose-us {
    padding-top: 3%;
    padding-bottom: 3%;
    background-color: #7A4F4B;
    border-top-left-radius: 133px;
    border-top-right-radius: 133px;
}

.choose-us > h1, 
.choose-us > p {
    text-align: center;
    color: #FFF2DD;
    font-family: 'DM Sans', sans-serif;
    font-size: 25px;
    margin-bottom: 3%;
}

.choose-us > h1 {
    font-size: 80px;
    margin: 0;
    padding-top: 3%;
}

.elements {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.get-started {
    display: flex;
    background-color: #FFFCF7;
    width: 90%;
    padding: 3%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    margin-top: 5%;
    align-items: center;
}

@media only screen and (max-width: 650px) {
    .get-started {
      flex-direction: column;
    }
}

.get-started > h2 {
    width: fit-content;
    margin-left: 3%;
    margin-right: 3%;
}

.content > p {
    color: #343D48;
}

.get-started > .content {
    align-items: center;
    text-align: center;
    color: #343D48;
    font-size: larger;
}

#contact-us-button {
    background-color: #D9D9D9;
    padding: 1% 3% 1% 3%;
    border: none;
    border-radius: 150px;
}