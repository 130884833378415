.header{
    background-color: #E8DBCE;  
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 0px;
    align-items: center;
}

.header > div {
    display: flex;
    align-items: center;
}

#logo {
    width: 20vw;
    min-width: 15%;
    object-fit: contain;
    padding: 5px 3vw;
}

.vertical-line {
    border-left: 0.01em solid #3F0000;
    height: 2em;
    margin: 0.5vw;
}

.fa {
    color: #E8DBCE;
    width: 0%;
    height: 0%;
}

@media only screen and (max-width: 500px) {
    .fa {
        width: 5vw;
        height: 5vw;
        color: #3F0000;
    }

    .open{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        width: 50%;
        height: 100vh;
        background: #E8DBCE;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
        align-items: center;
    }

    .closed {
        height: 10%;
    }

    .vertical-line {
        border-left: 0px solid #3F0000;
        margin: 0px;
    }

    #logo {
        width: 20vw;
        min-width: 150px;
        object-fit: contain;
        padding: 5px 3vw;
    }
}