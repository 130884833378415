.services-page {
    background-color: #FFFCF7;
    display: inline;
    display: flex;
    flex-direction: column;
    padding: 3%;
}

.services-page > h1{
    font-family: 'DM Serif Display', serif;
    font-weight: lighter;
    font-size: 50px;
    margin:0px;
    text-align: left;
    color: #3F0000;
}

.services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 4%;
}